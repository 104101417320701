<script setup lang="ts" generic="T">
import type { UISliderEditorialWithArrows } from './UISliderEditorialWithArrows.props'
import emblaCarouselVue from 'embla-carousel-vue'

const props = withDefaults(defineProps<UISliderEditorialWithArrows<T>>(), {
  hasNoSpaceBetween: false,
  cardDimensions: 'small',
  type: 'slider',
  mobileCentered: false,
  padding: false,
})

const [emblaRef, emblaApi] = emblaCarouselVue({
  loop: props.type === 'slider' ? true : false,
  dragFree: false,
  containScroll: props.mobileCentered ? false : 'trimSnaps',
  align: props.mobileCentered ? 'center' : 'start',
  breakpoints: {
    '(min-width: 1024px)': {
      active: props.type === 'slider' ? true : false,
      containScroll: 'trimSnaps',
      startIndex: props.type === 'slider' ? (props.items?.length ?? 0) : 0,
      align: 'start',
    },
  },
})
const { ts } = useI18n()

const items = computed(() => {
  if (!props.items?.length) return
  if (props.type === 'default') return props.items

  const maxSlidesPerView = 5
  let _tempArray = props.items

  while (_tempArray.length < maxSlidesPerView * 2) {
    _tempArray = _tempArray.concat(_tempArray)
  }
  return _tempArray
})
const visibleNavigationPanel = ref<null | 'L' | 'R'>(null)

const scrollPrev = () => emblaApi.value?.scrollPrev()
const scrollNext = () => emblaApi.value?.scrollNext()

const mousemoveHandler = (e: MouseEvent) => {
  if (e.pageX < window.screen.width / 2) {
    visibleNavigationPanel.value = 'L'
  } else {
    visibleNavigationPanel.value = 'R'
  }
}

const isSlider = computed(() => {
  return props.type === 'slider'
})

const _slidesPerViewDesktop = computed(() => {
  if (props.cardDimensions === 'small') {
    return isSlider.value ? 4 : 4
  } else if (props.cardDimensions === 'medium') {
    return isSlider.value ? 3 : 3
  } else if (props.cardDimensions === 'large') {
    return isSlider.value ? 1.2 : 3
  }
})
const _slidesPerViewMobile = ref<number>(1.3)
</script>
<template>
  <div
    class="slider-container relative"
    @mousemove="(e: any) => mousemoveHandler(e as MouseEvent)"
    @mouseleave="visibleNavigationPanel = null"
  >
    <!-- Navigation back -->
    <div
      v-if="isSlider"
      class="bg-background-secondary ml:flex w-xxl xl:w-4xl absolute bottom-0 left-0 top-0 z-10 hidden h-[var(--editorial-slider-image-height,100%)] items-center justify-center opacity-0 transition-opacity duration-500"
      :class="visibleNavigationPanel === 'L' && `opacity-100`"
    >
      <button
        class="flex h-full w-full items-center justify-center"
        :aria-label="ts('aria-labels.prevSlide')"
        @click="scrollPrev"
      >
        <DefaultIconsChevronLeft
          :aria-hidden="true"
          class="icon-editorial-slider"
        />
      </button>
    </div>

    <!-- Navigation next -->
    <div
      v-if="isSlider"
      class="bg-background-secondary ml:flex w-xxl xl:w-4xl absolute bottom-0 right-0 top-0 z-10 hidden h-[var(--editorial-slider-image-height,100%)] items-center justify-center opacity-0 transition-opacity duration-500"
      :class="visibleNavigationPanel === 'R' && 'opacity-100'"
      :aria-label="ts('aria-labels.nextSlide')"
    >
      <button
        class="flex h-full w-full items-center justify-center"
        :aria-label="ts('aria-labels.nextSlide')"
        @click="scrollNext"
      >
        <DefaultIconsChevronRight
          :aria-hidden="true"
          class="icon-editorial-slider"
        />
      </button>
    </div>
    <div
      :class="{
        'carousel-container-overflowed': isSlider,
        'mx-sm md:mx-md lg:mx-xxl xl:mx-4xl': props.padding && !isSlider,
      }"
    >
      <div class="overflow-hidden" :class="{ 'lg:px-xxl xl:px-4xl': isSlider }">
        <div
          ref="emblaRef"
          class="embla"
          :style="{
            '--slides-per-view-mobile': _slidesPerViewMobile,
            '--slides-per-view-desktop': _slidesPerViewDesktop,
          }"
        >
          <div
            class="embla__container flex cursor-grab select-none gap-[1px]"
            :class="{
              'gap-0': hasNoSpaceBetween,
            }"
            grab-cursor
          >
            <template
              v-if="
                selectedProductsStatus === 'idle' ||
                selectedProductsStatus === 'pending'
              "
            >
              <div
                v-for="index in 10"
                :key="`skeleton__${index}`"
                class="embla__slide !h-[initial] shrink-0"
              >
                <SkeletonUIProductTile />
              </div>
            </template>

            <template
              v-if="
                items &&
                items.length &&
                (selectedProductsStatus == null ||
                  selectedProductsStatus === 'success')
              "
            >
              <div
                v-for="(item, index) in isSlider ? [...items, ...items] : items"
                :key="`item__${index}`"
                class="embla__slide !h-[initial] shrink-0"
              >
                <slot v-bind="{ item, index }" />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.embla {
  --slides-per-view-mobile: 1.3;
  --slides-per-view-desktop: 4;

  .embla__container {
    .embla__slide {
      flex: 0 0 calc(100% / var(--slides-per-view-mobile));
      min-width: 0;

      @screen lg {
        flex: 0 0 calc(100% / var(--slides-per-view-desktop));
      }
    }
  }
}

.icon-editorial-slider {
  color: var(--colorStrokePrimary);
}
</style>
